<template>
  <span>
    <!-- Hilfe Button -->
    <v-btn
      style="position: fixed; bottom: 40px; right: 40px; z-index: 1000"
      icon
      variant="outlined"
      color="blueprintBlue"
      v-if="$vuetify.display.mdAndUp"
      @click="showNavBar = !showNavBar"
    >
      <v-img src="@/assets/icon_chat.png" height="24" width="24" contain></v-img>
    </v-btn>
    <v-btn
      style="position: fixed; bottom: 40px; right: 0px; z-index: 1000"
      rounded="0"
      icon
      variant="outlined"
      color="blueprintBlue"
      v-else
      @click="showNavBar = !showNavBar"
    >
      <v-img src="@/assets/icon_chat.png" height="24" width="24" contain></v-img>
    </v-btn>
    <v-card
      v-if="showNavBar"
      class="text-doveGrey px-4 py-10 bg-spaceBlue"
      style="border: 1px solid #5960f6; position: fixed; bottom: 100px; right: 40px; z-index: 1000"
      width="280px"
      rounded="lg"
      variant="outlined"
      color="spaceBlue"
    >
      <v-card-title class="text-center text-energyRed"> Du brauchst Hilfe? </v-card-title>
      <v-card-text class="text-center">
        <v-btn
          variant="outlined"
          rounded="pill"
          class="px-6 mt-3"
          href="mailto:support@spotmyenergy.de?subject=Hilfe im Vertragsportal"
          >Schreibe uns eine Mail</v-btn
        >
        <v-btn
          variant="outlined"
          rounded="pill"
          class="px-6 mt-3"
          href="https://spotmyenergy.de/faq"
          rel="noopener"
          target="_blank"
          >Schau im FAQ nach</v-btn
        >
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  export default {
    name: "contactNavbar",
    data: () => ({
      showNavBar: false,
    }),
    computed: {
      //
    },
  };
</script>
