<template>
  <v-app class="bg-spaceBlue">
    <v-main class="ma-4">
      <router-view />
    </v-main>
    <!-- <v-footer color="spaceBlue" height="170">
      <v-container fluid class="text-left" style="padding-left: 330px;" v-if="$vuetify.display.lgAndUp">
        <p class="font-weight-bold">SpotmyEnergy GmbH</p>
        <p>Hafenstr. 4a</p>
        <p>Köln NRW 51063</p>
        <p>Germany</p>
      </v-container>
      <v-container fluid class="text-center" v-else>
        <p class="font-weight-bold">SpotmyEnergy GmbH</p>
        <p>Hafenstr. 4a</p>
        <p>Köln NRW 51063</p>
        <p>Germany</p>
      </v-container>
    </v-footer> -->
    <contactNavbar />
  </v-app>
</template>

<script>
  import contactNavbar from "./components/ui/contactNavbar.vue";
  export default {
    name: "App",
    data: () => ({
      //
    }),
    components: {
      contactNavbar,
    },
    computed: {
      //
    },
  };
</script>
