import { createApp } from "vue";
import App from "./App.vue";

import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import firebase from "../firebase.js";
import "./styles/fonts.css";

loadFonts();

const app = createApp(App);

// Globale Funktion definieren
app.config.globalProperties.$filters = {
  money(value) {
    const formatter = Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    });
    return formatter.format(value);
  },
};

app.use(vuetify).use(router).use(firebase).mount("#app");
